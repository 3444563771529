document.addEventListener('DOMContentLoaded',function() {
    
    const switcher = document.getElementsByClassName('js-switcher')[0],
	    
    init = function() {
        
        const dimensions = document.getElementsByClassName('js-dimensions')[0],
              inputs = switcher.getElementsByTagName('input'),
              standard = document.getElementsByClassName('js-standrad-height')[0];
           
        const toggle = function(status) {
            if (status) {
                //document.getElementsByClassName('js-height')[0].classList.remove('no-visibility');
                document.body.classList.add('i-know-the-area');
                standard.classList.add('is-hidden');
                
            } else {
                //document.getElementsByClassName('js-height')[0].classList.add('no-visibility');
                document.getElementsByTagName('walls_height').value = 260;
                document.body.classList.remove('i-know-the-area');
                standard.classList.remove('is-hidden');
            }
        }
        
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].addEventListener('change', function(e) {
                inputs[1].checked ? toggle(false) : toggle(true) ;  
            });
        }
    };

    switcher ? init() : false
    

}, false)
