window.calc = function() {
    let request = new XMLHttpRequest(), 
        action = document.getElementsByClassName('js-calcForm')[0].getAttribute('action');
        
    const addSeparator = function(nStr) { 
        nStr += '';
        var x = nStr.split('.');
        var x1 = x[0];
        var x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        
        while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ' ' + '$2');
        }
        
        return x1 + x2;
    };
                            
    request.onreadystatechange = function() {                
        if (request.readyState == XMLHttpRequest.DONE) {   // XMLHttpRequest.DONE == 4
            if (request.status == 200) {
                                            
                const data = JSON.parse( request.responseText );
                
                console.log(data);
                
                // All data from Calc form
                document.getElementById('order').value = request.responseText;
                
                // System prices
                document.getElementsByClassName('js-premium-flugger-price')[0].innerHTML = addSeparator(data.premiumFluggerPrice);
                document.getElementsByClassName('js-premium-flugger-labour')[0].innerHTML = addSeparator(data.premiumFluggerLabour);
                document.getElementsByName('premium-flugger-price')[0].value = data.premiumFluggerPrice;
                document.getElementsByName('premium-flugger-labour')[0].value = data.premiumFluggerLabour;
                
                document.getElementsByClassName('js-standard-pro-gold-price')[0].innerHTML = addSeparator(data.standardProGoldPrice);
                document.getElementsByClassName('js-standard-pro-gold-labour')[0].innerHTML = addSeparator(data.standardProGoldLabour);
                document.getElementsByName('standard-pro-gold-price')[0].value = data.standardProGoldPrice;
                document.getElementsByName('standard-pro-gold-labour')[0].value = data.standardProGoldLabour;
                
                document.getElementsByClassName('js-eco-semin-price')[0].innerHTML = addSeparator(data.ecoSeminPrice);
                document.getElementsByClassName('js-eco-semin-labour')[0].innerHTML = addSeparator(data.ecoSeminLabour);
                document.getElementsByName('eco-semin-price')[0].value = data.ecoSeminPrice;
                document.getElementsByName('eco-semin-price')[0].value = data.ecoSeminLabour;
                
                // Summary
                document.getElementsByClassName('js-labour')[0].innerHTML = addSeparator(data.labour);
                document.getElementsByClassName('js-summaryprice')[0].innerHTML = addSeparator(data.summaryPrice);
                document.getElementsByClassName('js-windowsprice')[0].innerHTML = addSeparator(data.windowsPrice);
                document.getElementsByClassName('js-wrappingprice')[0].innerHTML = addSeparator(data.wrappingPrice);

            } else {
                swal("Wystąpił błąd", "Problem z przekazaniem danych", "error") 
                console.log(request.responseText);
            }                    
        }
    }
    
    let fd = new FormData(document.querySelector('.js-calcForm'));
    
    // if (document.querySelector('#i-dont-know-the-area').checked) {
    //     fd.delete('i-know-the-area');
    // }

    request.open("post", action, true);            
    request.send(fd);
};
