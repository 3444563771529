import css from './sass/style.scss';

require('./js/calc.js')
require('./js/dimensions.js')
require('./js/helpers.js')
require('./js/polyfills.js')
require('./js/init.js')
require('./js/ismobile.js')
require('./js/steps.js')
require('./js/switcher.js')
require('./js/validate.js')

require('./img/products/flugger.jpg')
require('./img/products/flugger@2x.jpg')
require('./img/products/pro-gold.jpg')
require('./img/products/pro-gold@2x.jpg')
require('./img/products/semin.jpg')
require('./img/products/semin@2x.jpg')
