import smoothscroll from 'smoothscroll-polyfill';
import swal from 'sweetalert';

smoothscroll.polyfill();

document.addEventListener('DOMContentLoaded',function() {

    const btn = document.getElementsByClassName('js-nextstep')[0],
    
    init = function() {

        const body = document.body,
              header = document.getElementsByClassName('js-header')[0],
              dimensions = document.getElementsByClassName('js-dimensions')[0].getElementsByTagName('input'),
              options = document.getElementsByClassName('js-options')[0].querySelectorAll('input[type="checkbox"]');

        btn.step = 1;        

        const clearErrors = function(e) {
            e.currentTarget.classList.remove('error');
        };
        
        const step1 = function() {

            const validate = function() {
                let error = 0;

                for (let i = 0; i < dimensions.length; i++) {                    
                    if (dimensions[i].value > 0) {
                        dimensions[i].removeEventListener('keydown', clearErrors);
                    } else {
                        dimensions[i].classList.add('error');
                        dimensions[i].addEventListener('keydown', clearErrors)
                        error = 1;
                    }
                }
            
                return error;
            }
            
            const errors = validate();
            
            if (!errors) {
                scroll({
                    top: 0,
                    behavior: "smooth"
                })
                                
                for (let i = 0; i < dimensions.length; i++) {
                    dimensions[i].readOnly = true;
                }
                
                body.classList.remove('step-1');
                body.classList.add('step-2');
                btn.step = 2;
                btn.innerHTML = btn.dataset.title.split(',')[1];
                
                // if user don't know the area
                if (document.querySelector('#i-dont-know-the-area').checked) {
                    document.getElementsByName('powierzchnia-pomieszczenia')[0].value = 
                    document.getElementsByName('powierzchnia-pomieszczenia')[0].value * 3;
                }

                !window.local ? window.calc() : false;
            }
        };
        
        const step2 = function() {
            
            const validateOptions = function() {
                let error = 0;
                
                // clear fields
                for (let i = 0; i < options.length; i++) {
                    let field = options[i].parentNode.getElementsByClassName('js-option')[0];
                    field.classList.remove('error');
                }

                for (let i = 0; i < options.length; i++) {
                    if (options[i].checked) {                        
                        let field = options[i].parentNode.getElementsByClassName('js-option')[0];

                        if (field.value > 0) {
                            field.removeEventListener('keydown', clearErrors);
                        } else {
                            field.classList.add('error');
                            field.addEventListener('keydown', clearErrors)
                            error = 1;
                        }
                    }
                }
                
                return error;
            };

            const validateSystems = function() {
                const system = document.getElementsByClassName('js-system');
                let error = 0;

                // if system is choosed
                for (let i = 0; i < system.length; i++) {
                    if (system[i].checked) {
                        error = 0;
                        break;
    
                    } else {
                        error = 1;
                    }
                }                
                return error;
            };

            const optionsChecked = function() {
                let out = 0;
                
                for (let i = 0; i < options.length; i++) {
                    if (options[i].checked) {
                        out = 1;                        
                        break;

                    } else {
                        out = 0;
                    }
                }

                return out;
            }

            
            // if system choosed
            if (!validateSystems()) {
                
                // if user doesn't check any option, hide them
                if (!optionsChecked()) {
                    document.getElementsByClassName('js-options')[0].classList.add('is-hidden');
                    step3();

                } else {
                                        
                    // validate fields in Options
                    if(!validateOptions()) {
                        step3();
                    }
                }

            } else {

                let window_pos = window.pageYOffset || window.scrollY || document.documentElement.scrollTop, 
                    target = window_pos + document.getElementById('system').getBoundingClientRect().top;	        
        	    
                scroll({
                    top: target - 30,
                    behavior: "smooth"
                })
                swal("Wybierz system", "", "info") 
            }
            
        };
        
        const step3 = function() {
            
            //let tmp = [{ "lat" : "2312", "lon" : "5322"}];
            //const data = JSON.parse(JSON.stringify(request.responseText));
            //console.log(data);
            
            
            body.classList.remove('step-2');
            body.classList.add('step-3');
            btn.step = 3;
            btn.innerHTML = btn.dataset.title.split(',')[2];
            document.getElementsByClassName('js-area').readOnly = true;
            
            header.innerHTML = header.dataset.title.split(',')[1];

            for (let i = 0; i < options.length; i++) {
                if (options[i].checked) {                        
                    let field = options[i].parentNode.getElementsByClassName('js-option')[0];
                    field.readOnly = true;
                }
            }
            
            scroll({
                top: 0,
                behavior: "smooth"
            })

            !window.local ? window.calc() : false;
        }
        
        const action = function(e) {
            switch(e.currentTarget.step) {
                case 1: step1(); break;
                case 2: step2(); break;
            }            
        };
    
        btn.addEventListener('click', action);
    };

    btn ? init() : false;
    

}, false);
