document.addEventListener('DOMContentLoaded',function() {
    
    // Filter inputs for numbers only
    
    (function() {
        
        // Restricts input for the given textbox to the given inputFilter.
        const setInputFilter = function(textbox, inputFilter) {
            ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function(event) {
                textbox.addEventListener(event, function() {
                    if (inputFilter(this.value)) {
                        this.oldValue = this.value;
                        this.oldSelectionStart = this.selectionStart;
                        this.oldSelectionEnd = this.selectionEnd;
                    } else if (this.hasOwnProperty("oldValue")) {
                        this.value = this.oldValue;
                        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                    }
                });
            });
        };
    
        const digits = document.getElementsByClassName('js-digits');

        const digitsOnly = function() {
            for (let i = 0; i < digits.length; i ++) {
                setInputFilter(digits[i], function(value) {
                    return /^\d*\.?\d*$/.test(value);
                });
            }
        };

        digits ? digitsOnly() : false;
    
    })();

}, false)