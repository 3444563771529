document.addEventListener('DOMContentLoaded',function() {
    
    const dimensions = document.getElementsByClassName('js-dimensions')[0],
	    
    init = function() {
        
        const areaInput = document.getElementsByClassName('js-area')[0],
              edit = document.getElementsByClassName('js-edit')[0],
              dimensions = document.getElementsByClassName('js-dimensions')[0];

        const changeArea = function() {
            !window.local ? window.calc() : false;
        }

        const toggleEdit = function() {
            
            for (let i = 0; i < dimensions.getElementsByTagName('input').length; i++) {
                if (dimensions.getElementsByTagName('input')[i].readOnly) {
                    dimensions.getElementsByTagName('input')[i].readOnly = false;
                    document.documentElement.classList.add('edit-dimensions');

                    areaInput.addEventListener('change', changeArea);
                    
                } else {
                    dimensions.getElementsByTagName('input')[i].readOnly = true;
                    document.documentElement.classList.remove('edit-dimensions');
                    
                    areaInput.removeEventListener('change', changeArea);
                }
            }
        };
      
        edit.addEventListener('click', toggleEdit);
    };

    dimensions ? init() : false

}, false)
